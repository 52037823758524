import React, { useCallback, useEffect, useState } from 'react';
import cardanoIcon from 'Assets/Icons/Blockchains/cardano.svg';
import useHttp from 'App/hooks/use-http';
import { getBlockchainIcon } from 'App/helpers/blockchain';
import SkeletonLoader from '../UI/SkeletonLoader';

const BlockChainIcon = (props) => {

    const { triggerAPI } = useHttp();
    const [blockchainIcon, setBlockchainIcon] = useState('');
    const [loading, setLoading] = useState(true);

    const onResponseHandler = useCallback(({ data }) => {
        if(!!data?.blockchainType?.code){
            setBlockchainIcon(getBlockchainIcon(data?.blockchainType?.code))
        } else {
            setBlockchainIcon(cardanoIcon)
        }
        setLoading(false)
    }, []);

    const onErrorHandler = () => {
        setBlockchainIcon(cardanoIcon);
        setLoading(false)
    }

    const getBlockchainData = (policyId) => {
        triggerAPI({
            url: `community/collection/${policyId}`, method: 'get'
        }, onResponseHandler, onErrorHandler);
    }

    useEffect(() => {
        if (!!props?.policyId) {
            getBlockchainData(props.policyId);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.policyId])

    return (loading ? 
        <SkeletonLoader height={props?.loaderH ?? '1.2rem'} width={props?.loaderW ?? '1.2rem'} count={1} inline={true} /> :
        <img className={props?.classes ?? ``} alt="Img" src={blockchainIcon} />
    )
}

export default BlockChainIcon