import './App.scss';
import Main from './Layouts/Main';
import Store from './Store';

function App() {
  return (
    <Store>
      <div className="App h-full">
        <Main />
        {/* Portal to display the crop modal above all */}
        <div id={`react-crop-ui-root`} />
      </div>
    </Store>
  );
}

export default App;
