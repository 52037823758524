import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonLoader = (props) => {
    const color = {
        baseColor: "var(--cu-black-500)",
        highlightColor: "var(--cu-black-110)",
      };
      const defaultConfig = {
        ...color,
        ...props,
      };
    return (
        <>
        < Skeleton {...defaultConfig} />
        {props?.details === "img-2-line" ? (
         <div className="flex items-center pt-2 details-wrapper gap-2">
         <Skeleton
           circle
           width={50}
           height={50}
           inline={props?.inline}
           {...color}
         />
         <div className="text-details">
           <Skeleton
             count={2}
             inline={props?.inline}
             height={"0.5rem"}
             {...color}
           />
         </div>
       </div>
      ) : 
        props?.details === "1-line" && (
          <div className="text-details">
            <Skeleton
              count={1}
              inline={props?.inline}
              height={"0.5rem"}
              {...color}
            />
          </div>
        )
      }
        </>
    )
}
export default SkeletonLoader;