import ModalOuter from 'App/Components/UI/ModalOuter';
import React, { useState } from 'react';
import closeIcon from "Assets/Icons/close.svg";
import './PieceDetailsView.scss';
import SkeletonLoader from 'App/Components/UI/SkeletonLoader';
import AttributesTab from './AttributesTab';
import dummyImage from "Assets/Images/dummypic.svg";

const PieceDetailsView = ({ openPopup, onClosePopup, data }) => {
    const [imageLoaded, setImageLoaded] = useState(false);

    return (
        <>
            {openPopup && <ModalOuter openModal={openPopup}
                parentClass={openPopup ? '!z-[11]' : '!hidden !z-[11]'}
            >
                <div className="piece_details_view-container" >
                    <div className="flex justify-end items-start px-[1rem]" >
                        <button onClick={onClosePopup}><img src={closeIcon} alt="close" /></button>
                    </div>
                    <div className="piece_main_details" >
                        <div className={`piece_main-image_box ${imageLoaded && `border`}`} >
                            <img className={imageLoaded ? 'w-full h-full object-contain' : 'h-0 w-0'} src={data?.piece?.nftcdnimage1024 ?? dummyImage} alt={data?.piece?.collectionName ?? 'piece image'} onLoad={() => setImageLoaded(true)} />
                            {!imageLoaded &&
                                <SkeletonLoader height={`100%`} baseColor={"var(--cu-black-100)"}
                                    highlightColor={"var(--cu-black-110)"} />
                            }
                        </div>
                        <div className="piece_details_box" >
                            <div className='community_box'>
                                <div className='w-12 aspect-square'>
                                    <img className='w-full h-full object-cover rounded-full' src={data?.community?.displayPicture ?? dummyImage} alt={data?.community?.name} />
                                </div>

                                <div className='flex flex-col gap-2'>
                                    <div className='flex gap-6 items-center'>
                                        <span className='text-[1.2rem] font-semibold max-w-[800px] truncate'>{data?.piece?.collectionName ?? '-'}</span>
                                        <span>{data?.collectionId}</span>
                                    </div>
                                    <span>{data?.community?.name ?? 'No community'}</span>
                                </div>
                            </div>

                            <div className="pieces_description-details border-[#191D23]  border-b pb-4">
                                <div className="description-data flex flex-col">
                                    <div className="title">Description</div>
                                    <div className="data">
                                        {data?.piece?.description ? (
                                            Array.isArray(data?.piece?.description) ? (
                                                data?.piece?.description.map((line, index) => (
                                                    <span key={index}>
                                                        {line}{' '}
                                                    </span>
                                                ))
                                            ) : (
                                                <div>{data?.piece?.description}</div>
                                            )
                                        ) : (
                                            <span>No description</span>
                                        )}
                                    </div>

                                </div>
                            </div>


                            <div className="piece_information">
                                <div className="info_header">Information</div>
                                <div className="max-h-[48dvh] w-full overflow-y-scroll overflow-x-hidden">
                                    <AttributesTab nft={data?.piece} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalOuter>}
        </>
    )
}

export default PieceDetailsView