import "./ToggleSwitch.scss"
const ToggleSwitch = ({ onChange, row, className,keyName, check = '' }) => {
    const toggleHandler = (e) => {
        onChange(row.id, e.target.checked,keyName)
    }
    const checked = check
    return (
        <div className={`flex items-start ${className}`}>
            <label className="switch">
                <input type="checkbox" checked={checked} onChange={toggleHandler} />
                <span className="slider round"></span>
            </label>
        </div>
    );
}

export default ToggleSwitch