import { useStore } from "App/hooks-store/store";
import API from "App/helpers/api";
import { useCallback } from "react";
import useAuth from "./use-auth";
import errorMessage from "App/helpers/errorMessage";

const useHttp = () => {
    const dispatch = useStore(false)[1]
    const logout = useAuth()[1]
    const errorHandler = useCallback((error) => {
        const { message: globalMessage, response: { status, data: { error: { message = '' } = {} } = {} } } = error;
        switch (status) {
            case 401:
                logout();
                break;
            case 404:
                dispatch('showToast', { toast: { toastMode: 'error', globalMessage: errorMessage().capitalize(globalMessage) } })
                break;

            default:
                dispatch('showToast', { toast: { toastMode: 'error', message: errorMessage().capitalize(message) } })
                break;
        }
        dispatch('hideSpinner')
    }, [dispatch, logout])
    const triggerAPI = useCallback(async (request = { url: 'noUrl', data: {}, method: 'get' || 'post' || 'put' || 'delete' }, applyData) => {
        try {
            const response = await API(request)

            applyData(response.data)
        } catch (error) {
            errorHandler(error)
            throw error
        }
    }, [errorHandler])
    return { triggerAPI }
}
export default useHttp;