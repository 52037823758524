import PDFObject from "pdfobject";
import React from "react";

const PdfViewer = ({width = "100%", height = "100%", src, containerID ="pdf-viewer", onLoad = ()=>{}}) => {  
  React.useEffect(() => {
    const options = {
      forcePDFJS: true,
    };

    PDFObject.embed(src, `#${containerID}`, options);
    onLoad();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src, containerID]);

  return (
    <div id={containerID} style={{ width, height }} />
  );
};

export default PdfViewer;