import React, { useEffect, useCallback, useRef, useState } from "react";
import "./InfiniteLoader.scss";
import DetailsSkeltonCard from "../DetailsSkeleton";
import { FindOddOrEven, isMobileOS } from "App/helpers/utilities";

const InfiniteLoader = ({
  loading,
  children,
  loadData,
  hasMore,
  loader,
  loaderCount = 5,
  scrollOffset = isMobileOS() ? 2000 : 100,
  parentDiv = "",
  containerStyle = "",
  noAbsolute,
  parentScrollContainerQuery,
  mobileScrolling = isMobileOS(),
  subScrollContainerQuery,
  containerClassName,
  size,
  length
}) => {
  const debounceTimeout = useRef(null);
  const [scrollingOffset, setScrollingOffset] = useState(scrollOffset);

  const reFetchHandler=()=>{
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      loadData();
    }, 200); // Adjust debounce delay as needed
  }
  // Handle parent container scroll event
  const onScrollHandler = useCallback(
    (e) => {
      let target = e.target;
      if (mobileScrolling) {
        target = document.scrollingElement;
      }
      if (
        hasMore &&
        !loading &&
        target.scrollTop + target.clientHeight >
          target.scrollHeight - scrollingOffset
      ) {
        reFetchHandler()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasMore, loading, mobileScrolling, loadData, scrollingOffset]
  );

  // Handle sub-scroll container logic
  const onSubScrollHandler = useCallback(
    () => {
      const scrollParent = document.querySelector(parentScrollContainerQuery);
      const subScroll = document.querySelector(subScrollContainerQuery);

      if (!scrollParent || !subScroll) return;

      const subScrollContentBottom = subScroll.getBoundingClientRect().bottom;
      const scrollParentBottom = scrollParent.getBoundingClientRect().bottom;

      if (hasMore && !loading && subScrollContentBottom <= (scrollParentBottom + scrollingOffset)) {
        reFetchHandler()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasMore, loading, loadData, scrollingOffset, parentScrollContainerQuery, subScrollContainerQuery]
  );

  // Apply scroll event listener based on subScrollContainerQuery
  useEffect(() => {
    const scrollParent = document.querySelector(parentScrollContainerQuery);

    if (subScrollContainerQuery && hasMore) {
      const subScroll = document.querySelector(subScrollContainerQuery);

      if (subScroll && scrollParent) {
        // Listen to the scroll event on the parent container
        scrollParent.onscroll = onSubScrollHandler;
      }
    } else if (scrollParent) {
      // Fallback to listening to the parent scroll
      scrollParent.onscroll = onScrollHandler;
    }

    // Cleanup listener
    return () => {
      if (scrollParent) {
        scrollParent.onscroll = null;
      }
    };
  }, [hasMore, subScrollContainerQuery, parentScrollContainerQuery, onScrollHandler, onSubScrollHandler]);

  useEffect(() => {
    if (mobileScrolling) {
      setScrollingOffset(2000);
      document.body.onscroll = onScrollHandler;
    } else {
      setScrollingOffset(scrollOffset);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileScrolling, scrollOffset, onScrollHandler]);

  useEffect(() => {
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, []);
  const {isOdd} = FindOddOrEven(length)
  return (
    <div
      className={`infinite-scroll-component  ${containerStyle} ${isOdd&&"show-even"}`}
      onScroll={onScrollHandler}
    >
      {children}
      {hasMore ? (
        loader === "split-posts" ? (
          <div className={`posts-skeleton-wrap`}>
            <DetailsSkeltonCard
              show={true}
              height={"100%"}
              count={2}
              inline={true}
              noAbsolute
              details="posts"
              parentDiv={`split-row`}
              containerClassName={`flex`}
            />
          </div>
        ) : (
          <DetailsSkeltonCard
            show={true}
            height={"100%"}
            count={loaderCount || 5}
            inline={true}
            size={size}
            details={
              loader === "skelton"
                ? ""
                : loader === "details-skeleton"
                ? "img-2-line"
                : loader === "posts"
                ? "posts"
                : loader === "creators-filter" ?
                  "md-profile-img-with-one-line-text":
                  loader
            }
            noAbsolute
            containerClassName={`flex ${containerClassName}`}
            parentDiv={parentDiv}
          />
        )
      ) : (
        <></>
      )}
    </div>
  );
};

export default InfiniteLoader;
