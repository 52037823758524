/* eslint-disable react-hooks/exhaustive-deps */
import InputBox from "App/Components/UI/Forms/InputBox";
import Validators from "App/Components/UI/Forms/Validators";
import {
  getInitial,
  markAsTouched,
  validateFormGroup,
} from "App/helpers/formValidation";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import { useCallback, useEffect, useState } from "react";
import closeIcon from "Assets/Icons/close.svg";
import { useNavigate } from "react-router-dom";
import User from "App/Components/UI/User";
import TableHeader from "App/Components/UI/Table/Components/TableHeader";
import Table from "App/Components/UI/Table";
import Button from "App/Components/UI/Button";
import "./AddUserToCommunity.scss";
import { useDebounce } from "App/hooks/use-debounce";

const AddUserToCommunity = (props) => {
  const { triggerAPI } = useHttp();
  const  dispatch = useStore(false)[1];
  const [formValidState, setFormValidState] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [touched, setTouched] = useState(false);
  const [usersLists, setUsersLists] = useState(null);
  const formGroup = {
    email: {
        value: undefined,
        isRequired: true
    }
}
const [formValues, setFormValues] = useState(getInitial(formGroup)) // email form
  const formUserGroup = {
    user: {
        value: undefined,
        isRequired: true
    }
}
const [formValidSelectedUserState, setFormValidSelectedUserState] = useState({});
const [formSelectedUserValues, setFormSelectedUserValues] = useState(getInitial(formUserGroup)); // user form
const [touchedSelectedUser, setTouchedSelectedUser] = useState(false);
const [isValidSelectedUser, setIsValidSelectedUser] = useState(false);
useEffect(() => {
  if (touchedSelectedUser) {
    const newValues = markAsTouched(formSelectedUserValues);
    setFormSelectedUserValues(newValues);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [touchedSelectedUser]);

useEffect(() => {
  const { valid } = validateFormGroup({
    errors: formValidSelectedUserState,
    formGroup:formUserGroup,
    values: formSelectedUserValues,
  });
  setIsValidSelectedUser(valid);
}, [formSelectedUserValues, formValidSelectedUserState, formUserGroup]);
const collectUserErrors = useCallback((error) => {
  setFormValidSelectedUserState((prev) => {
    return {
      ...prev,
      ...error,
    };
  });
}, []);
  const navigate = useNavigate();
  const searchValue = useDebounce(formValues?.email, 600);
  useEffect(() => {
    if (touched) {
      const newValues = markAsTouched(formValues);
      setFormValues(newValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [touched]);

  useEffect(() => {
    const { valid } = validateFormGroup({
      errors: formValidState,
      formGroup,
      values: formValues,
    });
    setIsValid(valid);
  }, [formValues, formValidState, formGroup]);
  const collectErrors = useCallback((error) => {
    setFormValidState((prev) => {
      return {
        ...prev,
        ...error,
      };
    });
  }, []);
  const inputOnChange = (e) => {
    setFormValues((prev) => {
      return {
        ...prev,
        ...e,
      };
    });
  };
  const getUsersListsResult = useCallback(
    (res) => {
      dispatch("hideSpinner");
      const { list } = res.data;

      setUsersLists(list);
    },
    [dispatch]
  );
  const getUserList = (search) => {
    dispatch("showSpinner");
    triggerAPI(
      {
        url: `admin/user/list`,
        data: {
          page: 1,
          items: 1000,
          search: `${search}`,
        },
        method: "post",
      },
      getUsersListsResult
    );
  };
  const saveHandler = useCallback(() => {
    if (isValid) {
        dispatch("showSpinner");
        getUserList(formValues?.email);
    } else {
      setTouched(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, formValues, isValid, triggerAPI]);
  useEffect(() => {
    if (searchValue||searchValue==="") {
      saveHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);
  const gotToBack = () => {
    if (props?.onClosePopup) {
      props?.onClosePopup();
    } else {
      navigate(-1);
    }
  };
  const addUserHandler = (usr) => {
    setFormSelectedUserValues((prev) => {
        return {
          ...prev,
          user:usr,
        };
      });
  };
  const addUserToCommunityApiHandlerResult = useCallback(
    (res) => {
      dispatch("hideSpinner");
      gotToBack();
      props?.onRefetchList && props?.onRefetchList();
    },
    [dispatch]
  );
  const addUserToCommunityApiHandler = () => {
    dispatch("showSpinner");
    triggerAPI(
      {
        url: `admin/claim-user-community`,
        data: {
          userId: formSelectedUserValues?.user?.id,
          communityId: props?.communityId,
        },
        method: "post",
      },
      addUserToCommunityApiHandlerResult,
      () => {
        dispatch("hideSpinner");
      }
    );
  };
  const addUserToCommunityHandler = () => {
    if (isValidSelectedUser) {
      addUserToCommunityApiHandler();
  } else {
    setTouchedSelectedUser(true);
  }
  };
  return (
    <div className="add-user-to-community">
      <div className="w-full flex items-center justify-between px-[1rem]">
        <span />
        <h2 className="!text-[1rem]">Add User</h2>
        <button onClick={gotToBack}>
          <img src={closeIcon} alt="close" />
        </button>
      </div>
      <div className="flex gap-4 w-full">
        <div className={`flex w-full  flex-col sm:gap-2 gap-[0.375rem]`}>
          <div>
            <InputBox
              label="Email Address"
              validateOnBlur
              type="text"
              value={formValues.email}
              controlName="email"
              onChange={inputOnChange}
            />
            <Validators
              type="Required"
              value={formValues.email}
              message="Required field"
              controlName="email"
              onErrors={collectErrors}
            />
            <Validators
              type="Email"
              value={formValues.email}
              message="Invalid Email"
              controlName="email"
              onErrors={collectErrors}
            />
          </div>
        </div>
      </div>
      <div>
        {!!usersLists && (
          <Table>
            <thead className="text-left">
              <tr>
                <th className="sticky left-0">
                  <TableHeader label="User" />
                </th>
                <th>
                  <TableHeader label="Email" />
                </th>
              </tr>
            </thead>
            <tbody>
              {usersLists?.map((usr) => (
                <tr
                  key={usr.id}
                  className={`${
                    formSelectedUserValues?.user?.id === usr?.id ? "active" : "in-active"
                  }`}
                  onClick={() => addUserHandler(usr)}
                >
                  <td
                    className="sticky left-0"
                  >
                    <User
                      label={usr?.username}
                      src={usr.profilePicUrl}
                      className="user-table"
                    />
                  </td>
                  <td>{usr.email}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
          <Validators
              type="Required"
              value={formSelectedUserValues?.user}
              message="Select the user"
              controlName="user"
              onErrors={collectUserErrors}
            />
        {!!(usersLists && !usersLists?.length) && (
          <div className="text-center no-rows pt-4">
            <p>No data</p>
          </div>
        )}
        {usersLists?.length && (
          <div className="flex flex-col gap-4 buttons_container">
            <div className="flex justify-center gap-4 pt-4">
              <Button
                label="Cancel"
                onClick={gotToBack}
                className="transaction-cancel"
              />
              <Button
                className={`transaction-ok ${!formSelectedUserValues?.user ? "disable" : ""}`}
                label={"Add"}
                onClick={addUserToCommunityHandler}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddUserToCommunity;
