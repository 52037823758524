const { initStore } = require("./store")

const configureStore = () => {
    const actions = {
        showToast: (current, toast = { toastMode: '', message: '' }) => {
            return toast
        },
        hideToast: (current) => {
            return {
                toast: {
                    toastMode: null,
                    message: null
                }
            }
        }
    }
    initStore(actions, {
        toast: {
            toastMode: null,
            message: null
        }
    });
}
export default configureStore;