import { useEffect, useState } from "react";
import "./Tabs.scss";
import { NavLink } from "react-router-dom";

const Tabs = ({
  tabList = [],
  type = "",
  disabled,
  selectedTab,
  handleTab = () => {},
  containerClass = "",
  iterateContainerClass = "",
  tabClass = "",
}) => {
  const [activeTab, setActiveTab] = useState("");
  useEffect(() => {
    if (selectedTab !== activeTab) {
      setActiveTab(selectedTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);
  const handleTabClick = (e) => {
    if (!disabled) {
      let selected = e?.currentTarget?.dataset?.id;
      handleTab(selected, e);
    }
  };
  return (
    <div
      className={`common_style-tab-container ${containerClass} tab-type-${type}`}
    >
      <div className={`tab-data ${iterateContainerClass}`}>
        {tabList?.map((tab, index) => {
          if (tab?.url) {
            return (
              <NavLink
                data-id={tab?.id}
                key={tab?.id || index + 1}
                to={tab?.url}
                onClick={handleTabClick}
                className={`tab ${tabClass} ${tab?.className} ${
                  tab?.id === activeTab || (!tab?.id && !activeTab)
                    ? "active"
                    : !disabled
                    ? "not-active"
                    : "cursor-not-allowed"
                }`}
              >
                {!!tab?.image && (
                  <div className="img-wrap">
                    <img src={tab?.image} alt="" />
                  </div>
                )}
                {tab?.element}{tab?.count ? `(${tab?.count})` : null}
              </NavLink>
            );
          }
          return (
            <div
              onClick={handleTabClick}
              data-id={tab?.id}
              key={tab?.id}
              className={`tab ${tab?.className} ${tabClass} ${
                tab?.id === activeTab || (!tab?.id && !activeTab)
                  ? "active"
                  : !disabled
                  ? "not-active"
                  : "cursor-not-allowed"
              }`}
            >
              {!!tab?.image && (
                  <div className="img-wrap">
                    <img src={tab?.image} alt="" />
                  </div>
                )}
              {tab?.element}{tab?.count ? `(${tab?.count})` : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Tabs;
