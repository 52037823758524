import React, { useEffect, useRef, useState } from 'react'
import './PiecesList.scss';
import SkeletonLoader from "App/Components/UI/SkeletonLoader";
import useHttp from 'App/hooks/use-http';
import defaultImage from 'Assets/Icons/logo-square.svg'

const PiecesList = ({ collectionInfo,setAsThumbnailHandler, onSelectHandler }) => {
    const { triggerAPI } = useHttp();

    let [page, setPage] = useState(0);
    const [loading, setLoading] = useState(true);
    const [piecesList, setPiecesList] = useState(null);
    const pageCount = 32;


    const getAssetDetails = (data) => {
        let filteredAssets = data?.filter((a) => a?.asset_name !== '' && a?.asset_name !== null);
        let filteredAssetsList = filteredAssets?.filter(el => el?.policy_id === collectionInfo?.collectionId);
        setPiecesList((prev) => piecesList?.length ? [...prev, ...filteredAssetsList] : [...filteredAssetsList])
        setLoading(false);
    };

    useEffect(() => {
        if (collectionInfo?.assets?.length && collectionInfo?.collectionId) {
            let assets = collectionInfo?.assets?.filter((a) => a?.asset_name !== '' && a?.asset_name !== null);
            assets = assets?.slice(page * pageCount, page * pageCount + pageCount);
            assets = assets?.map(item => { return { asset_name: item?.asset_name, policy_id: item?.policy_id ?? collectionInfo?.collectionId, chain: item?.chain } })

            triggerAPI(
                {
                    url: `user/nft-collection/asset/detail?networkType=MAINNET&blockchainTypeId=1`,
                    method: "post",
                    data: {
                        assetids: assets
                    }
                },
                getAssetDetails
            );

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collectionInfo, page]);

    const onScrollHandler = (e) => {
        if (
            e.target.scrollTop + e.target.clientHeight >
            e.target.scrollHeight - 10
        ) {
            if (piecesList?.length === (page + 1) * pageCount) {
                page++;
                setPage(page);
                setLoading(true);
            }
        }
    };

    return (
        <div className='pieces_list-container' onScroll={onScrollHandler}>
            <div className='list_grid-box mb-2'>
                {piecesList?.length ? piecesList?.map((item, i) => (
                    <ImageWithLoader
                        data={item}
                        image={item?.nftcdnimage256}
                        setAsThumbnailHandler={setAsThumbnailHandler}
                        key={`${item?.asset}${i}`}
                        onSelectHandler={onSelectHandler}
                    />
                )
                ) : null}
                {loading && [...Array(32)]?.map((_item, i) =>
                    <div key={i + 1} className={`aspect-square`}>
                        <SkeletonLoader height={`100%`} baseColor={"var(--cu-black-100)"}
                            highlightColor={"var(--cu-black-110)"} />
                            <SkeletonLoader height={15} count={1} inline={true} />
                    </div>)
                }
            </div>
        </div>
    )
}


function ImageWithLoader({ image=defaultImage, data,setAsThumbnailHandler, onSelectHandler }) {
    const [imgLoading, setImageLoading] = useState(true);
    const pieceRef = useRef();
    useEffect(() => {
        const img = new Image();
        img.src = image;

        img.onload = () => {
            setImageLoading(false);
        };
    }, [image]);

    const SetThumbnailClick=(e)=>{
        e.stopPropagation();
        setImageLoading(true)
        pieceRef.current=setTimeout(()=>{
            setImageLoading(false);
        },500)
        setAsThumbnailHandler(image) 
    }
    useEffect(()=>{
        return ()=>{
            clearTimeout(pieceRef.current)
        }
    },[])
    return (
<div>

        <div className={`aspect-square`}>
            {imgLoading ? (
                <div className={"aspect-square"}>
                    <SkeletonLoader height={`100%`} baseColor={"var(--cu-black-100)"}
                        highlightColor={"var(--cu-black-110)"} />
                </div>
            ) : (
                <div
                    className='aspect-square overflow-hidden collection-asset'
                    onClick={() => onSelectHandler(data)}
                >
                    <img className='aspect-square w-full h-full object-cover cursor-pointer' src={image} alt="" />
                    <div className='actions-wrapper'>
                        <div className={`set-as-thumbnail`} onClick={SetThumbnailClick}>Set as thumbnail</div>
                    </div>
                    </div>
            )}

{imgLoading ? (
                <div className={""}>
                   <SkeletonLoader height={15} count={1} inline={true} />
                </div>
            ) : (
                <div className='text-white'>
                {data?.quantity}
                </div>
            )}
        </div>
        
                  
            
        </div>
    );
};

export default PiecesList