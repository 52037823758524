import PageTab from "App/Components/UI/PageTab";
import { useStore } from "App/hooks-store/store";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";



const CollectionManagement = () => {
    const tabs = [{
        link: `collections`,
        label: 'All'
    }, 
    {
        link: `new-collections`,
        label: 'Unreviewed'
    }, 
    {
        link: `verify-collections`,
        label: 'Verify'
    }, 
    {
        link: `verified-collections`,
        label: 'Verified'
    },
    {
        link: `rejected-collections`,
        label: 'Rejected'
    },
    {
        link: `collectors-collections`,
        label: 'Collectors'
    },
]
    const dispatch = useStore(false)[1];
    useEffect(() => {
        dispatch('setPageTitle', 'Collection Management')
    }, [dispatch])

    return (
        <div>
            <PageTab tabs={tabs} />
            <div className="py-4">
                <Outlet />
            </div>
        </div>
    )
}
export default CollectionManagement;