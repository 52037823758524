import axios from "axios";
import storage from "./storage";
const baseURL = process.env.REACT_APP_BASE_URL

const API = ({ url = 'noUrl', data = {}, method = 'get' || 'post' || 'put' || 'delete' ,extraHeaders='',responseType=''}) => {
    const token = storage().get('token')
    let headers = {
        Authorization: `Bearer ${token}`
    }
    if(extraHeaders){
        headers={...headers,...extraHeaders}
    }
    return axios({
        method,
        url: `${baseURL}/${url}`,
        data,
        headers,
        responseType  
    })
}
export default API;