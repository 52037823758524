import React, { useCallback, useMemo, useState } from 'react';
import './ProofOfCraftPopup.scss';
import { IFrame } from 'App/Components/UI/Iframe';
import PdfViewer from 'App/Components/UI/PdfViewer';
import SkeletonLoader from "App/Components/UI/SkeletonLoader";
import CloseIcon from 'App/Components/IconsComponents/CloseIcon';
import LeftArrow from 'App/Components/IconsComponents/LeftArrow';
import RightArrow from 'App/Components/IconsComponents/RightArrow';

const ProofOfCraftPopup = ({ onClose, proofOfCraftList, selectedFile, setSelectedFile }) => {

  const [fileLoaded, setFileLoaded] = useState(false);

  const currentIndex = useMemo(() => {
    return proofOfCraftList?.findIndex(item => item?.location === selectedFile?.location) ?? 0;
  }, [selectedFile, proofOfCraftList]);

  const handleNext = useCallback(() => {
    const nextIndex = (currentIndex + 1) % proofOfCraftList?.length;
    setSelectedFile(proofOfCraftList[nextIndex]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proofOfCraftList, currentIndex]);

  const handlePrevious = useCallback(() => {
    const prevIndex = currentIndex === 0 ? proofOfCraftList.length - 1 : currentIndex - 1;
    setSelectedFile(proofOfCraftList[prevIndex]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proofOfCraftList, currentIndex]);

  const onLoad = (e) => {
    setFileLoaded(true);
  }

  return (
    <div className={`proof-of-craft-popup_container`}>
      <div className={`proof-of-craft-popup_inline-wrapper`}>
        <CloseIcon className="close-btn" onClick={onClose} />

        <div className={`proof-file-preview-section`}>

          <div className={`${fileLoaded ? 'proof-craft-file-box opacity-100' : 'opacity-0 h-0'}`}>
            {selectedFile?.type === 'image' ? <img onLoad={onLoad} className={`pc_file-image`} src={selectedFile?.location} alt='file' />
              : selectedFile?.type === 'pdf' ?
                <PdfViewer className={`pc_file-pdf`} onLoad={onLoad} src={selectedFile?.location}></PdfViewer>
                : selectedFile?.type === 'youtube' ?
                  <IFrame className={`pc_file-youtube`} onLoad={onLoad} >
                    {selectedFile?.location}
                  </IFrame>
                  : null}
          </div>

          <div className={`${fileLoaded ? 'hidden' : 'proof-file-skelton-loader-wrapper'}`}>
            <SkeletonLoader width={`100%`} height={`100%`} />
          </div>

        </div>

        <div className={`proof-file-controls-wrapper`}>
          <div className={`flex gap-6 items-center`}>
            <LeftArrow className={`control-icon activeIcon`} onClick={handlePrevious}/>
            <RightArrow className={`control-icon activeIcon`} onClick={handleNext}/>
          </div>
        </div>

      </div>
    </div>
  )
}

export default ProofOfCraftPopup