
import React, { useRef } from 'react';
import './Iframe.scss';

export const IFrame = ({
  children,
  ...props
}) => {  
  const reshapehtml = (rawhtml) => {
    var doc = new DOMParser().parseFromString(rawhtml,"text/html");                  
    if (doc.body)
    {
      doc.body.style.overflow = "hidden";  
      doc.body.style.padding = "0px";  
      doc.body.style.margin = "0px";  
      rawhtml = doc.documentElement.outerHTML;
    }
    return rawhtml
  }

  if (children?.toLowerCase().startsWith("data:text/html;base64,"))
  {   
    let rawhtml = atob(children.substring(children.indexOf(',')+1));
    let modifiedhtml = reshapehtml(rawhtml);      
    children = `data:text/html;base64,${btoa(modifiedhtml)}`      
  }
  else if (children?.toLowerCase().startsWith("data:text/html;utf8,"))
  {
    let rawhtml = children.substring(children.indexOf(',')+1);
    let modifiedhtml = reshapehtml(rawhtml);      
    children = `data:text/html;utf8,${modifiedhtml}`    
  }
  else if (children?.toLowerCase().startsWith("data:text/html,"))
  {
    let rawhtml = children.substring(children.indexOf(',')+1);
    let modifiedhtml = reshapehtml(rawhtml);      
    children = `data:text/html,${modifiedhtml}`    
  }
  const onLoad = () => {
    props?.onLoad();
  }
  const refer = useRef();
  return (
    <iframe ref={refer} title='IFRAME' sandbox="allow-scripts allow-same-origin" id='myiFrame' src={children} className={` ${props?.className} iframe-container overflow-hidden`} onLoad={onLoad}>
    </iframe>
  )
}