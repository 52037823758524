import useHttp from "App/hooks/use-http";

const useCommunityHelper = () => {
  let { triggerAPI } = useHttp();
  const getUserCollectionDataAPI = (data, callback) => {
    triggerAPI(
      {
        url: `community/creators/collections`,
        data,
        method: "post",
      },
      (res) => {
        const { list, pageMeta } = res?.data;
        callback({ list, pageMeta });
      }
    );
  };
  return {
    getUserCollectionDataAPI,
  };
};
export default useCommunityHelper;
