import React, { useState } from 'react';
import './ProofOfCraft.scss';
import CloseIcon from 'App/Components/IconsComponents/CloseIcon';
import PdfIcon from 'Assets/Icons/PDF-Icon.svg';
import youtubeIcon from 'Assets/Icons/YouTube.svg'
import ProofOfCraftPopup from './ProofOfCraftPopup';

const ProofOfCraft = (props) => {
    const [showPopup, setShowPopup] = useState(false);
    const [selectedFile, setSelectedFile] = useState('');

    const getYoutubeThumbnail = (url) => {
        const videoId = url?.split('/')[4]?.split('?')?.[0];
        return `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`
    }

    const getThumbnailFromType = (type, url) => {
        switch (type) {
            case 'image':
                return url;
            case 'pdf':
                return PdfIcon;
            case 'youtube':
                return getYoutubeThumbnail(url);
            default:
                return url;
        }
    }

    const handleFileClick = (file) => {
        setSelectedFile(file);
        setShowPopup(true);
    }

    const handleRemove = (e, removedOne) => {
        e?.stopPropagation();
        const filteredData = props?.proofOfCrafts?.filter?.((item) => (removedOne?.location !== item?.location));
        props?.onProofOfCrafts(filteredData)

        // store user removed files for delete from s3 bucket after when user updated collection details
        if (removedOne?.type !== 'youtube') {
            const removedProofOfCraft = {
                folder: "proof-of-craft",
                location: removedOne?.location
            }
            props?.onDeleteProofOfCrafts((prev) => ([...prev, removedProofOfCraft]));
        }
    }

    return (<>
        <div className='proof-of-craft_container'>
            <div className={`pc_upload-files-title`}>
                <p>Proof of Craft</p>
            </div>

            <div className={`pc_files-cards`}>
                {props?.proofOfCrafts?.length ?
                    props?.proofOfCrafts?.map((item, index) => (<div onClick={() => handleFileClick(item)} className={`pc_file`} key={item?.location ?? index + 1}>
                        <div className={`pc_actions-wrapper`}>
                            <CloseIcon className={`pc_close-icon activeIcon`} onClick={(e) => handleRemove(e, item)} />
                        </div>
                        {item?.type === 'youtube' && <img src={youtubeIcon} className={`yt-play_icon`} alt='youtubeIcon' />}
                        <img className={`pc_file-image`} src={getThumbnailFromType(item?.type, item?.location)} alt='file' />
                        <p className={'clamp_ellipsis'}>{item?.name}</p>
                    </div>))
                    :
                    <div>
                        <label>No items</label>
                    </div>
                }
            </div>
        </div>
        {showPopup && <ProofOfCraftPopup
            onClose={() => setShowPopup(false)}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            proofOfCraftList={props?.proofOfCrafts}
        />
        }
    </>)
}

export default ProofOfCraft