import React, { useState, useRef, useMemo } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import userAvatarHover from "Assets/Icons/avatar_hover.svg";
import defaultImg from "Assets/Images/dummypic.svg";
import "./ImageUploadWithCrop.scss";
import "react-image-crop/dist/ReactCrop.css";
import { useDebounceEffect } from "./components/useDebounceEffect";
import { canvasPreview } from "./components/canvasPreview";
import { useStore } from "App/hooks-store/store";
import Button from "App/Components/UI/Button";
import ReactDOM from "react-dom";


const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB
const MIN_FILE_SIZE = 50 * 1024; // 50KB

// Helper function to generate cropped image URL from canvas
const canvasToBlob = (canvas) => {
    return new Promise((resolve) => {
        canvas.toBlob((blob) => {
            resolve(blob ? URL.createObjectURL(blob) : null);
        }, "image/jpeg");
    });
};

// Helper function to center and aspect crop
const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
    return centerCrop(
        makeAspectCrop(
            { unit: "%", width: 90 },
            aspect,
            mediaWidth,
            mediaHeight
        ),
        mediaWidth,
        mediaHeight
    );
};

const ImageUploadWithCrop = ({
    imageHandle,
    uploadedImage,
    label,
    type,
    keyName,
    recommendedSize,
    isCropAspect,
    dropMessage,
    inputId,
    id,
    croppedLabel,
    canvasStyle,
    isSquareImage
}) => {
    const dispatch = useStore(false)[1];
    const previewCanvasRef = useRef(null);
    const imgRef = useRef(null);

    const [imgSrc, setImgSrc] = useState("");
    const [crop, setCrop] = useState(null);
    const [completedCrop, setCompletedCrop] = useState(null);
    const [showCropModal, setShowCropModal] = useState(false);
    const [croppedImageUrl, setCroppedImageUrl] = useState(null);

    const cropAspect = useMemo(() => isCropAspect ?? 16 / 9, [isCropAspect]);

    const onSelectFile = (e) => {
        const file = e?.target?.files?.[0];
        if (!file) return;

        const fileSize = file.size;
        const errorMessages = {
            tooLarge: "File size exceeds the 2MB limit.",
            tooSmall: "File size is smaller than 50KB."
        };

        if (fileSize > MAX_FILE_SIZE || fileSize < MIN_FILE_SIZE) {
            const message = fileSize > MAX_FILE_SIZE ? errorMessages.tooLarge : errorMessages.tooSmall;
            dispatch("showToast", {
                toast: { toastMode: "error", message }
            });
            return;
        }

        setCrop(null);
        const reader = new FileReader();
        reader.onload = () => setImgSrc(reader.result?.toString() || "");
        reader.readAsDataURL(file);
        setShowCropModal(true);
        e.target.value = ""; // Reset the input
    };

    const onImageLoad = (e) => {
        const { width, height } = e.currentTarget;
        setCrop(centerAspectCrop(width, height, cropAspect));
    };

    useDebounceEffect(
        async () => {
            if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
                canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
                const croppedImage = await canvasToBlob(previewCanvasRef.current);
                setCroppedImageUrl(croppedImage);
            }
        },
        100,
        [completedCrop]
    );

    const handleCancel = () => {
        setImgSrc("");
        setCroppedImageUrl("");
        setShowCropModal(false);
    };

    const handlePassCrop = async () => {
        const canvas = previewCanvasRef?.current;
        const croppedBlob = await new Promise((resolve) => canvas.toBlob(resolve, "image/jpeg"));
        if (croppedBlob) {
            imageHandle({ [keyName]: croppedBlob }, croppedImageUrl, handleCancel);
        }
    };

    const cropModal = (
        <div className="react-image-crop-overlay">
            <div className="react-image-crop-box">
                <div className="inline-crop-wrapper">
                    {!!completedCrop && (
                        <div className="crop-preview-container" style={canvasStyle || { width: completedCrop.width, height: completedCrop.height }}>
                            <label className="label">{croppedLabel ?? 'Profile'}</label>
                            <canvas ref={previewCanvasRef} style={canvasStyle || { objectFit: "cover", borderRadius: '50%' }} />
                        </div>
                    )}
                    <div className="react-crop-container">
                        <label className="label">Original</label>
                        <ReactCrop crop={crop} onChange={(_, percentCrop) => setCrop(percentCrop)} onComplete={(c) => setCompletedCrop(c)} aspect={cropAspect}>
                            <img ref={imgRef} alt="Crop me" src={imgSrc} onLoad={onImageLoad} />
                        </ReactCrop>
                    </div>
                </div>
                <div className="actions-wrapper">
                    <Button
                        label="Cancel"
                        onClick={handleCancel}
                        className="transaction-cancel"
                    />
                    <Button
                        className={`transaction-ok`}
                        label={`Save`}
                        onClick={handlePassCrop}
                    />
                </div>
            </div>
        </div>
    );


    return (
        <div className="react-image-crop-container">
            <div className={`image-field ${type === 1 ? "profile-image" : type === 2 ? "thumbnail-image" : "banner-image"} ${isSquareImage && "square-image"}`}>
                <div className="flex flex-col gap-[0.375rem]">
                    {!!label && <label className="label-text">{label}</label>}
                    <div className="image-wrapper" id={id}>
                        <label htmlFor={inputId}>
                            <img alt="Upload Icon" src={userAvatarHover} className="upload-icon" />
                            {!croppedImageUrl && (
                                <div className={`dummy-wrap ${uploadedImage ? "" : "default-placeholder-image"}`}>
                                    <img className="image-uploaded" alt="Default" src={uploadedImage ?? defaultImg} />
                                    {dropMessage && "Drop/Upload Files Here"}
                                </div>
                            )}
                            {!!croppedImageUrl && <img alt="Cropped" className="image-uploaded" src={croppedImageUrl} />}
                        </label>
                        <input type="file" id={inputId} name={keyName} className="hidden" accept="image/*" onChange={onSelectFile} />
                    </div>
                </div>

                {/* Portal for the crop modal */}
                {!!imgSrc && showCropModal && ReactDOM.createPortal(cropModal, document.getElementById("react-crop-ui-root"))}

                <div className="recommended-size">
                    {!!recommendedSize && <span>{`Recommended size ${recommendedSize} &`} </span>}
                    <span>File size must be between 50KB and 2MB.</span>
                </div>
            </div>
        </div>
    );
};

export default ImageUploadWithCrop;
