import { useMemo } from "react";

const useSlicedImageList = (dataList, sliceCount) => {
  return useMemo(() => {
    let list = [],
      remainCount = 0;
    if (dataList?.length) {
      list = dataList.slice(0, sliceCount);
      remainCount = dataList.length - list.length;
    }
    return { list, remainCount };
  }, [dataList, sliceCount]);
};

export default useSlicedImageList;
