import "./Pagination.scss";
import { useState, useEffect, useRef } from "react";
import ReactPaginate from 'react-paginate';
import InputBox from "../Forms/InputBox";
const Pagination = ({ itemsPerPage, total, selected = 0, initial = 0, rows = 0, onPageChange, showPageInput = false }) => {
    const [pageCount, setPageCount] = useState(0);
    const timeOut = useRef(null);
    const [pageInput, setPageInput] = useState(initial); 
    useEffect(() => {
        setPageCount(Math.ceil(total / itemsPerPage));
    }, [total, itemsPerPage]);
    const handlePageClick = (event) => {
        let newPageNo = event.selected + 1;
        onPageChange(newPageNo);
        setPageInput(newPageNo);
    };
    
    const handlePageInput = (e) => {
        if(e.pageNo && e.pageNo <= pageCount) {
            let newPageNo = e.pageNo;
            setPageInput(newPageNo);
            if (timeOut.current) {
                clearTimeout(timeOut.current)
            }
            timeOut.current = setTimeout(() => {
                onPageChange(newPageNo);
            }, 1000)
        }
        
    }
    const leftArrow = <span className="pagination-left"></span>
    const rightArrow = <span className="pagination-right"></span>
    const breakLabel = <span className="pagination-break"></span>
    return (
        <div className={`flex justify-center items-center pagination gap-8`}>
            <ReactPaginate
                breakLabel={breakLabel}
                nextLabel={rightArrow}
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                pageCount={pageCount}
                previousLabel={leftArrow}
                initialPage={pageInput}
                forcePage={selected - 1}
                renderOnZeroPageCount={null}
            />
            {showPageInput && 
             <InputBox
                type="number"
                placeholder={"Page No"}
                onChange={handlePageInput}
                controlName={'pageNo'}
                className={'pagination-input'}
                value={pageInput}
                />
            }
        </div>
    )
}
export default Pagination;