import useHttp from "App/hooks/use-http";

const useProofOfCraftApiHelper = () => {

    let { triggerAPI } = useHttp();

    const deleteProofOfCraftFiles = (dataArray) => {
        if (dataArray?.length) {
            // Create an array of promises
            const promises = dataArray.map((dataObject) =>
                new Promise((resolve, reject) => {
                    triggerAPI(
                        {
                            url: 'user/deleteFile',
                            method: 'post',
                            data: dataObject,
                        },
                        (res) => {
                            resolve(res);
                        },
                        (err) => {
                            reject(err);
                        }
                    );
                })
            );

            // Use Promise.all to wait for all API calls to finish
            Promise.all(promises)
                .then((results) => {
                    console.error('Error deleting files:', results);
                })
                .catch((errors) => {
                    // Handle any errors that occurred during the API calls
                    console.error('Error deleting files:', errors);
                });
        }
    }

    return { deleteProofOfCraftFiles };
};
export default useProofOfCraftApiHelper;