const { initStore } = require("./store")

const configureStore = () => {
    const actions = {
        setPageTitle: (current, title) => {
            return {
                pageTitle: title
            }
        }
    }
    initStore(actions, {
        pageTitle: ''
    });
}
export default configureStore;