import SearchFilter from "App/Components/UI/SearchFilter"
import Table from "App/Components/UI/Table"
import TableHeader from "App/Components/UI/Table/Components/TableHeader"
import ToggleSwitch from "App/Components/UI/ToggleSwitch"
import ActionButton from "Assets/Images/ActionButton.svg";
import Image from "App/Components/UI/Image"
import { useNavigate, useParams } from "react-router-dom"
import { useCallback, useEffect, useState } from "react";
import tableSort from "App/helpers/tableSort";
import ButtonAction from "App/Components/UI/ButtonAction";
import MenuDel from "Assets/Images/menu-del.svg";
import DeleteGallery from "./Components/DeleteGallery";
import useHttp from "App/hooks/use-http";
import { useStore } from "App/hooks-store/store";
import Pagination from "App/Components/UI/Pagination";
import "../../GalleryManagement.scss"
import { LIST_LIMIT } from "App/constants";
const actionMenu = [{
    label: 'Delete',
    action: 'delete',
    src: MenuDel
}]

const paginationConfig = {
    perPage: LIST_LIMIT
}

const Gallery = () => {
    const navigate = useNavigate();
    const [data, setData] = useState();
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [viewDeletePopup, setViewDeletePopup] = useState({ mode: false })
    const dispatch = useStore(false)[1];
    const { triggerAPI } = useHttp();
    const [search, setSearch] = useState('')
    const [filteredData, setFilteredData] = useState('')
    const { id } = useParams();
    const [initial, setInitial] = useState(true);

    const [galleryMenu, setGalleryMenu] = useState([
        {
            label: 'Show Displayed Frames',
            action: 'displayedFrames',
            selected: false,
            type: 'displayedFrames'
        },
        {
            label: 'Date Range',
            action: 'dateRange',
            selected: false,
            popup: true
        }
    ])
    const galleryDetailsHanlder = (e) => {
        const subId = e?.currentTarget?.dataset?.galleryid
        if (id) {
            navigate(`/dashboard/gallery-management/gallery/details/${subId}`);
        }
        else {
            navigate(`details/${subId}`);

        }
    }

    const [sort, setSort] = useState({ direction: 'DESC', sortKey: 'createdAt' })
    const onSortHandler = useCallback((sortKey) => {
        const dir = sort.sortKey === sortKey ? sort.direction : '';
        const direction = tableSort(dir)
        setSort({ direction, sortKey })
    }, [sort])
    const [list, setList] = useState();

    const onPageChangeHandler = (page) => {
        setPage(page)
                const root = document.getElementById('content');
        root.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
    }
    const actionHandler = (e) => {
        if (e.action === 'delete') {
            setViewDeletePopup({ mode: true })
            setData(e.row)
        }
    }
    const stopHandler = (e) => {
        e.stopPropagation();
    }
    const closeDeletePopup = (data) => {
        setViewDeletePopup({ mode: false })
        if (data) {
            getDeleteGallery()
        }
    }

    const searchHandler = (e) => {
        setSearch(e)
    }

    const getGalleryListResult = useCallback((res) => {
        dispatch('hideSpinner');
        const { list, pageMeta } = res.data
        setList(list)
        setTotalPage(pageMeta.totalItems)
    }, [dispatch])


    const getGalleryList = useCallback(() => {
        dispatch('showSpinner');
        const filter = {
            sortBy: sort.sortKey,
            orderBy: sort.direction,
            ...filteredData
        }
        if (id) {
            triggerAPI({
                url: `admin/user/galleries/${id}`, data: {
                    page: page,
                    items: paginationConfig.perPage,
                    search: `${search}`, filter
                }, method: 'post'
            }, getGalleryListResult);
        }
        else {
            triggerAPI({
                url: `gallery/list/get`, data: {
                    page: page,
                    items: paginationConfig.perPage,
                    search: `${search}`, filter
                }, method: 'post'
            }, getGalleryListResult);
        }

    }, [dispatch, filteredData, getGalleryListResult, id, page, search, sort.direction, sort.sortKey, triggerAPI])

    const apiResult = useCallback((res) => {
        const { message } = res;
        dispatch('hideSpinner')
        dispatch('showToast', { toast: { toastMode: 'success', message } })
        getGalleryList()
    }, [dispatch, getGalleryList])

    const getDeleteGallery = useCallback(() => {
        dispatch('showSpinner');
        const deleteData = {
            id: data?.id
        }
        triggerAPI({
            url: `gallery/admin/delete`, data: deleteData, method: 'delete'
        }, apiResult);
    }, [data?.id, dispatch, apiResult, triggerAPI])

    const toggleSwitchOnHomePage = (id, checked) => {
        patchGallery(id, checked, null);
    }
    const toggleSwitchInCoreLibrary = (id, checked) => {
        patchGallery(id, null, checked);
    }
    const onFilterData = (e) => {
        setFilteredData(e)
    }
    const patchGalleryResult = (res) => {
        getGalleryList()
        const { message } = res;
        dispatch('hideSpinner')
        dispatch('showToast', { toast: { toastMode: 'success', message } })
    }

    const patchGallery = (id, showOnHomePageDisplay, inCoreLibrary) => {
        dispatch('showSpinner');
        const data = {
            id: id            
        }
        if (showOnHomePageDisplay !== null)
        {
            data.showOnHomePageDisplay = showOnHomePageDisplay;
        }
        if (inCoreLibrary !== null) 
        {
            data.inCoreLibrary = inCoreLibrary;
        }
        triggerAPI({
            url: `gallery/display-status/update`, data, method: 'patch'
        }, patchGalleryResult);
    }
    useEffect(() => {
        if (page !== 1) {
            setPage(1)
        }
        else {
            getGalleryList();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sort, search, filteredData])

    useEffect(() => {
        if (!initial) {
            getGalleryList();
        }
        setInitial(false)


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    useEffect(() => {
        dispatch('setPageTitle', 'Gallery Management')
    }, [dispatch])

    return (
        <div>
            <div className="flex justify-between items-center mb-4">
                <div className='search-filter'>
                    <SearchFilter position={{ right: 150, top: 10 }} onSearch={searchHandler} data={filteredData} setActionMenu={setGalleryMenu} actionMenu={galleryMenu} onFilterData={onFilterData} />
                </div>
            </div>
            <div>
                <Table>
                    <thead className="text-left">

                        <tr>
                            <th>
                                <TableHeader label="Preview" />
                            </th>
                            <th>
                                <TableHeader label="Gallery" sort={sort} onSort={onSortHandler} sortKey="name" />
                            </th>
                            <th>
                                <TableHeader label="UserName" sort={sort} onSort={onSortHandler} sortKey="userName" />
                            </th>
                            <th>
                                <TableHeader label="Size" sort={sort} onSort={onSortHandler} sortKey="assetsCount" />
                            </th>
                            <th>
                                <TableHeader label="Created Date" sort={sort} onSort={onSortHandler} sortKey="createdAt"  />
                            </th>
                            <th>
                                <TableHeader label="Updated Date" sort={sort} onSort={onSortHandler} sortKey="updatedAt"  />
                            </th>
                            <th>
                                <TableHeader label="Homepage Display" sort={sort} onSort={onSortHandler} sortKey="showOnHomePageDisplay"/>
                            </th>
                            <th>
                                <TableHeader label="Core Library" />
                            </th>
                            <th>
                                <div className="flex justify-end items-center">
                                    <TableHeader label="Actions" />
                                </div>
                            </th>
                        </tr>

                    </thead>
                    <tbody className="gallery-body">
                        {list?.map(gallery => {
                            let imagePreview = gallery?.files?.sliders && gallery?.files?.sliders[0];
                            return (

                                <tr data-galleryid={gallery?.id} key={gallery.id} onClick={galleryDetailsHanlder}>

                                    <td>
                                        <div className="wall-image-gallery" style={{
                                            backgroundImage: `url(${gallery?.thumbnailBackground?.imageUrl})`
                                        }}>
                                            <Image src={imagePreview ? imagePreview : ''} className="gallery-image screenshot-image" loaderClass="nft-drop-loader" style={{ 'maxWidth': '4rem', 'Height': '4rem' }} />
                                        </div>
                                    </td>
                                    <td>
                                        {gallery?.name}
                                    </td>
                                    <td>
                                        {gallery?.user?.username}
                                    </td>
                                    <td>
                                        {gallery?.assetsCount}
                                    </td>
                                    <td>{new Date(gallery?.createdAt)?.toLocaleString()}</td>
                                    <td>{new Date(gallery?.updatedAt)?.toLocaleString()}</td>
                                    <td>
                                        <div className="flex gallery-home justify-center items-center" onClick={stopHandler}>
                                            <ToggleSwitch className="gallery-switch" check={gallery?.showOnHomePageDisplay} row={gallery} onChange={toggleSwitchOnHomePage} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="flex gallery-home justify-center items-center" onClick={stopHandler}>
                                            <ToggleSwitch className="gallery-switch" check={gallery?.inCoreLibrary} row={gallery} onChange={toggleSwitchInCoreLibrary} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="flex justify-end items-center" onClick={stopHandler}>
                                            <ButtonAction row={gallery} src={ActionButton} menu={actionMenu} onAction={actionHandler} />
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}

                    </tbody>
                </Table>
                {!list?.length &&
                    <div className="text-center no-rows pt-4">
                        <p>No Galleries found!</p>
                    </div>}
                <div className="mt-6">
                    <Pagination itemsPerPage={paginationConfig.perPage} total={totalPage} rows={list?.length} onPageChange={onPageChangeHandler} selected={page} />
                </div>
            </div>
            <DeleteGallery row={data} show={viewDeletePopup} onClose={closeDeletePopup} />

        </div>
    )
}

export default Gallery